import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "search" }
const _hoisted_2 = { class: "search-bar" }
const _hoisted_3 = ["placeholder"]
const _hoisted_4 = {
  key: 0,
  class: "results-count"
}
const _hoisted_5 = { class: "count" }
const _hoisted_6 = {
  key: 1,
  class: "inner"
}
const _hoisted_7 = { class: "block" }
const _hoisted_8 = { style: {"color":"black"} }
const _hoisted_9 = { class: "working-date" }
const _hoisted_10 = {
  key: 0,
  class: "tire"
}
const _hoisted_11 = { class: "description" }
const _hoisted_12 = { class: "link" }
const _hoisted_13 = {
  key: 0,
  class: "link2"
}
const _hoisted_14 = ["href"]
const _hoisted_15 = ["href"]
const _hoisted_16 = ["href"]
const _hoisted_17 = {
  key: 1,
  class: "link2"
}
const _hoisted_18 = ["href"]
const _hoisted_19 = ["href"]
const _hoisted_20 = {
  key: 2,
  class: "link2"
}
const _hoisted_21 = ["href"]
const _hoisted_22 = ["href"]
const _hoisted_23 = { key: 3 }
const _hoisted_24 = ["href"]
const _hoisted_25 = {
  key: 1,
  class: "hasResults"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        type: "text",
        placeholder: _ctx.$t('projects.search'),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchValue) = $event))
      }, null, 8, _hoisted_3), [
        [_vModelText, _ctx.searchValue]
      ]),
      _createElementVNode("i", {
        title: "Clear",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.clearSearch && _ctx.clearSearch(...args))),
        class: "fas fa-trash-alt"
      })
    ]),
    (_ctx.hasResults && _ctx.searchValue.length !== 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createTextVNode(_toDisplayString(_ctx.$t("projects.foundProjectsCount")), 1),
          _cache[2] || (_cache[2] = _createElementVNode("span", { class: "tire2" }, "―", -1)),
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.foundProjectsCount), 1),
          _cache[3] || (_cache[3] = _createTextVNode(" 👏😎 "))
        ]))
      : _createCommentVNode("", true),
    (_ctx.searchValue.length !== 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          (_ctx.hasResults)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.filteredProjects, (prj) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: prj.id,
                  class: "prj"
                }, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("h3", null, [
                      _createElementVNode("span", _hoisted_8, _toDisplayString(prj.id) + ". ", 1),
                      _createTextVNode(_toDisplayString(this.$i18n.locale === "uk" ? prj.title_ua : this.$i18n.locale === "es" ? prj.title_es : prj.title_en), 1)
                    ]),
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("span", null, _toDisplayString(this.$i18n.locale === "uk" ? prj.start_date_ua : this.$i18n.locale === "es" ? prj.start_date_es : prj.start_date_en), 1),
                      (prj.end_date_en)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_10, "–"))
                        : _createCommentVNode("", true),
                      _createElementVNode("span", null, _toDisplayString(this.$i18n.locale === "uk" ? prj.end_date_ua : this.$i18n.locale === "es" ? prj.end_date_es : prj.end_date_en), 1)
                    ]),
                    _createElementVNode("div", _hoisted_11, _toDisplayString(this.$i18n.locale === "uk" ? prj.description_ua : this.$i18n.locale === "es" ? prj.description_es : prj.description_en), 1),
                    _createElementVNode("div", null, [
                      _createElementVNode("strong", null, _toDisplayString(_ctx.$t('projects.type')) + ": ", 1),
                      _createTextVNode(_toDisplayString(this.$i18n.locale === "uk" ? prj.type_ua : this.$i18n.locale === "es" ? prj.type_es : prj.type_en), 1)
                    ]),
                    _createElementVNode("div", null, [
                      _createElementVNode("strong", null, _toDisplayString(_ctx.$t('projects.technologies')) + ": ", 1),
                      _createTextVNode(_toDisplayString(prj.techno), 1)
                    ]),
                    _createElementVNode("div", _hoisted_12, [
                      (prj.demolink && prj.youtubelink)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                            _createElementVNode("a", {
                              href: prj.demolink,
                              class: "demolink",
                              title: "Link to the project",
                              target: "_blank"
                            }, [
                              _createElementVNode("b", null, _toDisplayString(_ctx.$t('projects.link')), 1)
                            ], 8, _hoisted_14),
                            _createElementVNode("a", {
                              href: prj.sourcelink,
                              class: "github",
                              title: "Project at GitHub...",
                              target: "_blank"
                            }, [
                              _createElementVNode("b", null, _toDisplayString(_ctx.$t('projects.github')), 1)
                            ], 8, _hoisted_15),
                            _createElementVNode("a", {
                              href: prj.youtubelink,
                              class: "youtube",
                              title: "Project at YouTube...",
                              target: "_blank"
                            }, [
                              _createElementVNode("b", null, _toDisplayString(_ctx.$t('projects.youtube')), 1)
                            ], 8, _hoisted_16)
                          ]))
                        : (prj.demolink && prj.sourcelink)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                              _createElementVNode("a", {
                                href: prj.demolink,
                                class: "demolink",
                                title: "Link to the project",
                                target: "_blank"
                              }, [
                                _createElementVNode("b", null, _toDisplayString(_ctx.$t('projects.link')), 1)
                              ], 8, _hoisted_18),
                              _createElementVNode("a", {
                                href: prj.sourcelink,
                                class: "github",
                                title: "Project at GitHub...",
                                target: "_blank"
                              }, [
                                _createElementVNode("b", null, _toDisplayString(_ctx.$t('projects.github')), 1)
                              ], 8, _hoisted_19)
                            ]))
                          : (prj.sourcelink && prj.youtubelink)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                                _createElementVNode("a", {
                                  href: prj.sourcelink,
                                  class: "github",
                                  title: "Project at GitHub...",
                                  target: "_blank"
                                }, [
                                  _createElementVNode("b", null, _toDisplayString(_ctx.$t('projects.github')), 1)
                                ], 8, _hoisted_21),
                                _createElementVNode("a", {
                                  href: prj.youtubelink,
                                  class: "youtube",
                                  title: "Project at YouTube...",
                                  target: "_blank"
                                }, [
                                  _createElementVNode("b", null, _toDisplayString(_ctx.$t('projects.youtube')), 1)
                                ], 8, _hoisted_22)
                              ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_23, [
                                _createElementVNode("a", {
                                  href: prj.sourcelink,
                                  class: "github",
                                  title: "Project at GitHub...",
                                  target: "_blank"
                                }, [
                                  _createElementVNode("b", null, _toDisplayString(_ctx.$t('projects.github')), 1)
                                ], 8, _hoisted_24)
                              ]))
                    ])
                  ])
                ]))
              }), 128))
            : _createCommentVNode("", true),
          (_ctx.searchValue.length !== 0 && !_ctx.hasResults)
            ? (_openBlock(), _createElementBlock("div", _hoisted_25, _toDisplayString(_ctx.$t("projects.hasNoResults")) + "️ ", 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}