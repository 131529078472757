import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, vShow as _vShow } from "vue"

const _hoisted_1 = { class: "creeping-lines" }
const _hoisted_2 = ["title"]
const _hoisted_3 = ["title"]
const _hoisted_4 = ["title"]
const _hoisted_5 = ["title"]
const _hoisted_6 = { class: "inner" }
const _hoisted_7 = { class: "input-group" }
const _hoisted_8 = { for: "city" }
const _hoisted_9 = ["title"]
const _hoisted_10 = ["title"]
const _hoisted_11 = ["value"]
const _hoisted_12 = { class: "creep3d-weather" }
const _hoisted_13 = { class: "creep3d-nbu" }
const _hoisted_14 = { class: "creep3d-cryptos" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ToggleFullScreen = _resolveComponent("ToggleFullScreen")!
  const _component_CurrentDate = _resolveComponent("CurrentDate")!
  const _component_WeatherCreep3d = _resolveComponent("WeatherCreep3d")!
  const _component_NBURatesCreep3d = _resolveComponent("NBURatesCreep3d")!
  const _component_CryptosCreep3d = _resolveComponent("CryptosCreep3d")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, [
      _createVNode(_component_router_link, {
        class: "back-to-menu",
        to: "/extra/graphics",
        title: "Back to 3D Graphics page"
      }, {
        default: _withCtx(() => _cache[14] || (_cache[14] = [
          _createElementVNode("i", { class: "fa fa-arrow-circle-left" }, null, -1)
        ])),
        _: 1
      }),
      _createTextVNode(" " + _toDisplayString(_ctx.$t('extra.graphics.creep3d')) + " ", 1),
      _createVNode(_component_ToggleFullScreen),
      _cache[15] || (_cache[15] = _createTextVNode()),
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleFooter && _ctx.toggleFooter(...args))),
        class: "toggle-footer-btn",
        title: _ctx.isFooterHidden ? _ctx.$t('extra.openFooter') : _ctx.$t('extra.closeFooter')
      }, [
        _createElementVNode("i", {
          class: _normalizeClass(_ctx.isFooterHidden ? 'fas fa-toggle-on' : 'fas fa-toggle-off')
        }, null, 2)
      ], 8, _hoisted_2),
      _createElementVNode("i", {
        title: _ctx.weatherCreepView3d ? _ctx.$t('extra.graphics.titles.weather-close') : _ctx.$t('extra.graphics.titles.weather-start'),
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.changeWeatherCrip3d && _ctx.changeWeatherCrip3d(...args)))
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(['fa-solid', _ctx.weatherCreepView3d ? 'fa-sun' : 'fa-umbrella'])
        }, null, 2)
      ], 8, _hoisted_3),
      _createElementVNode("i", {
        title: _ctx.ratesCreepView3d ? _ctx.$t('extra.graphics.titles.currency-close') : _ctx.$t('extra.graphics.titles.currency-start'),
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.changeRatesCreep3d && _ctx.changeRatesCreep3d(...args)))
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(['fa', _ctx.ratesCreepView3d ? 'fa-wallet' : 'fa-sack-dollar'])
        }, null, 2)
      ], 8, _hoisted_4),
      _createElementVNode("i", {
        title: _ctx.cryptosCreepView3d ? _ctx.$t('extra.graphics.titles.cryptos-close') : _ctx.$t('extra.graphics.titles.cryptos-start'),
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.changeCryptosCreepView3d && _ctx.changeCryptosCreepView3d(...args)))
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(['fa-brands', _ctx.cryptosCreepView3d ? 'fa-ethereum' : 'fa-bitcoin'])
        }, null, 2)
      ], 8, _hoisted_5)
    ]),
    _cache[18] || (_cache[18] = _createElementVNode("line", null, null, -1)),
    _createVNode(_component_CurrentDate),
    _withDirectives(_createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('extra.weather.city')), 1),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          id: "city",
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.cityName) = $event)),
          onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.callHandleCityInputChange(_ctx.cityName))),
          onKeydown: _cache[6] || (_cache[6] = _withKeys(
//@ts-ignore
(...args) => (_ctx.callGetWeather && _ctx.callGetWeather(...args)), ["enter"])),
          ref: "cityInput"
        }, null, 544), [
          [_vModelText, _ctx.cityName]
        ]),
        _cache[17] || (_cache[17] = _createTextVNode()),
        _createElementVNode("i", {
          title: "Clear",
          onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.clearCity && _ctx.clearCity(...args))),
          class: "fas fa-trash-alt"
        }),
        _createElementVNode("button", {
          class: "get",
          onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.callGetWeather && _ctx.callGetWeather(...args))),
          title: _ctx.$t('extra.weather.btn')
        }, _toDisplayString(_ctx.$t('extra.weather.get')), 9, _hoisted_9),
        _createElementVNode("button", {
          class: "getMobile",
          onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.callGetWeather && _ctx.callGetWeather(...args))),
          title: _ctx.$t('extra.weather.btn')
        }, _cache[16] || (_cache[16] = [
          _createElementVNode("i", { class: "fas fa-arrow-circle-down" }, null, -1)
        ]), 8, _hoisted_10),
        _withDirectives(_createElementVNode("select", {
          class: "city-list",
          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.cityName) = $event)),
          onChange: _cache[11] || (_cache[11] = ($event: any) => (_ctx.callUpdateCityName(_ctx.cityName)))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cities, (city) => {
            return (_openBlock(), _createElementBlock("option", {
              key: city,
              value: city
            }, _toDisplayString(city), 9, _hoisted_11))
          }), 128))
        ], 544), [
          [_vModelSelect, _ctx.cityName]
        ])
      ])
    ], 512), [
      [_vShow, _ctx.weatherCreepView3d]
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createVNode(_component_WeatherCreep3d, {
        "weather-creep-view3d": _ctx.weatherCreepView3d,
        cityName: _ctx.cityName,
        "onUpdate:cityName": _cache[12] || (_cache[12] = ($event: any) => (_ctx.cityName = $event)),
        "onUpdate:cities": _cache[13] || (_cache[13] = ($event: any) => (_ctx.cities = $event)),
        ref: "weatherCreep3d"
      }, null, 8, ["weather-creep-view3d", "cityName"])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createVNode(_component_NBURatesCreep3d, { "rates-creep-view3d": _ctx.ratesCreepView3d }, null, 8, ["rates-creep-view3d"])
    ]),
    _createElementVNode("div", _hoisted_14, [
      _createVNode(_component_CryptosCreep3d, { "cryptos-creep-view3d": _ctx.cryptosCreepView3d }, null, 8, ["cryptos-creep-view3d"])
    ])
  ]))
}